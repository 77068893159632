* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  background-color: $colorWhite;
  font-size: 1.6rem;
  color: $colorBlack;
  line-height: 1.2;
  font-family: $defualtFont;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

img,
iframe {
  max-width: 100%;
}

.container {
  @extend %container;
}

#content {
  margin: 50px 0;

  @include sm {
    margin: 30px 0;
  }

  &.kontakt {
    margin-bottom: 0;

    .items {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: percentageWidth(1, 3);
        padding: 4rem;
        font-size: 2.4rem;
        font-weight: 600;
        text-align: center;

        @include sm {
          width: 100%;
        }

        img {
          margin-bottom: 3rem;

          @include sm {
            width: 50px;
          }
        }
      }
    }
  }
}

#gmap_canvas {
  width: 100%;
  height: 600px;

  @include sm {
    height: 100vw;
  }
}

.poglej-tudi {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;

  h3 {
    width: 100%;
    margin: 0 0 3rem;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .povezava {
    position: relative;
    width: percentageWidth(1, 4);
    padding: 1rem 1rem 6rem;

    @include sm {
      width: 50%;
    }

    @include xs {
      width: 100%;
    }
  }

  .slika {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    .image-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
    }

    .overlay {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 30px;
      background-color: rgba($colorGreen, 0.0);
      color: $colorWhite;
      z-index: 1;
      @include transition(background);

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        left: 15px;
        top: 15px;
        border: 1px solid $colorWhite;
      }

      .naslov {
        transform: translateY(40px);
        @include transition;

        @include sm {
          transform: translateY(0);
        }

        a {
          font-size: 1.9rem;
          font-weight: 600;
          text-transform: uppercase;
          color: $colorWhite;
        }
      }

      .besedilo {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%);
        @include transition;
        margin-top: 20px;
        border-top: 1px solid $colorWhite;
        padding-top: 20px;

        @include sm {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }

      &-link {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        color: transparent;
        font-size: 0;
      }
    }

    &:hover {
      .overlay {
        background-color: rgba($colorGreen, 0.5);

        .naslov {
          transform: translateY(0);
        }

        .besedilo {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
  }

  .vsebina {
    .naslov {
      margin: 2rem 0 1rem;
      font-weight: 600;
      text-transform: uppercase;

      a {
        color: $colorBlack;
        @include transition(color);

        &:hover {
          color: $colorGreen;
          text-decoration: none;
        }
      }
    }

    .besedilo {
      font-style: italic;
    }
  }

  .vec {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}

h3 {
  font-size: 2rem;
  margin-top: 4rem;

  &:first-of-type {
    margin-top: 0;
  }
}

a {
  color: $colorGreen;
  @include transition;

  &:hover {
    color: $colorGray;
    text-decoration: none;
  }
}

input {
  border-bottom: 1px solid;
}

fieldset {
  margin: 2rem 0;
  padding: 2rem;
  background-color: rgba($colorBlack, 0.1);
  border: 0;
}

label {
  display: block;
  font-weight: bold;
  padding: 1rem 0;
}

.vec a,
input.action,
a.gumb {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid $colorGray;
  padding: 10px 30px;
  color: $colorGray;
  cursor: pointer;
  @include transition;

  &:hover {
    background: $colorGreen;
    color: $colorWhite !important;
    border-color: $colorGreen;
  }
}

.sidebar-container {
  display: flex;
  flex-wrap: wrap;

  .with-sidebar {
    width: calc(100% - 400px);

    @include sm {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;

      &.not-full {
        width: auto;
      }
    }
  }

  .sticky-wrapper {
    width: 400px;

    @include sm {
      display: none;
    }

    .sidebar {
      top: 100px;
      width: 100%;
      height: calc(100vh - 130px);
      overflow-y: auto;
      padding: 0 0 0 2rem;

      &.stuck {
        position: fixed;
        width: inherit;
      }
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      width: 100%;
      height: 90px;
      text-align: center;
      padding: 2rem;
      color: $colorWhite;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $colorGray;

      &:last-of-type {
        margin-bottom: 0;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorGray;
        opacity: 0;
        z-index: 0;
        @include transition(opacity);
      }

      &.active::before,
      &:hover::before {
        opacity: 0.8;
      }
    }
  }
}