header,
#offset-menu,
footer {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
  z-index: 10;
  @include transition(background);

  .container {
    max-width: none;
    display: flex;
    align-items: center;
  }
}

.logo,
.logo-footer,
.logo-menu {
  width: 250px;

  a,
  img {
    display: block;
  }
}

.logo {
  margin-left: 1rem;
}

.meni {
  margin-left: auto;

  @include sm {
    display: none;
  }

  ul {
    display: flex;

    li {
      flex-shrink: 0;
      margin-right: 2rem;

      @include md {
        margin: 0 1rem;
      }
    }
  }

  a {
    color: $colorWhite;
    text-decoration: underline;
    font-weight: 300;
    text-transform: uppercase;
    @include transition(color);

    &:hover {
      color: $colorGreen;
    }
  }
}

.povezave,
.povezave-footer {
  ul {
    display: flex;
    align-items: center;
  }

  a,
  img {
    display: block;
  }

  img {
    max-height: 50px;
  }
}

header .povezave {
  @include md {
    display: none;
  }
}

body.sticky-header {
  header {
    background-color: rgba($colorBlack, 0.5);

    .meni a:hover {
      color: $colorGreen;
    }
  }

  .hamburger .text {
    opacity: 0;
  }
}

.banner {
  position: relative;
  min-height: 320px;
  background-size: cover;
  background-position: center;
  background-color: rgba($colorLightGray, 0.5);

  &.visja {
    min-height: 600px;

    @include sm {
      min-height: 320px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $colorGray;
    opacity: 0;
  }
}

#title {
  padding: 2rem;
  background: $colorDarkGray;
  color: $colorWhite;
  font-weight: bold;
  text-align: center;

  h1 {
    font-size: 3.6rem;
    margin: 0;
  }
}

.fotograf {
  color: $colorLightGray;
  font-size: 1.4rem;
  margin: 15px 0 30px 50px;

  @include md {
    margin: 10px 0 10px 10px;
  }
}