.home-meni {
  position: relative;
  height: 50vh;
  min-height: 400px;
  background-color: $colorGray;

  .container {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: 0;
  }

  .menu {
    color: $colorWhite;
    text-align: center;

    h2 {
      font-size: 3.2rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
      @include transition;

      @include sm {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }

    li {
      margin-bottom: 1.5rem;
    }

    a {
      color: $colorWhite;
      font-weight: 300;
      font-size: 1.8rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    ul {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

#cerknisko-jezero {
  border-bottom: 2px solid $colorWhite;

  &::before {
    background-image: url(../images/bg-jezero.png);
  }
}

#planinsko-polje {
  &::before {
    background-image: url(../images/bg-polje.png);
  }
}