.novice {
  > div {
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.novica {
  display: flex;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid $colorDarkGray;

  @include sm {
    flex-wrap: wrap;
  }

  a {
    color: $colorBlack;
    @include transition(color);

    &:hover {
      color: $colorGreen;
      text-decoration: none;
    }
  }

  .slika {
    width: 350px;
    margin-right: 3rem;

    @include sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    a, img {
      display: block;
    }

    img {
      width: 350px;
      height: 350px;
      object-fit: cover;

      @include sm {
        width: 100%;
        height: auto;
      }
    }
  }

  .vsebina {
    display: flex;
    width: calc(100% - 350px);
    flex-wrap: wrap;
    align-content: flex-start;
    flex-direction: column;

    @include sm {
      width: 100%;
    }

    .naslov {
      width: 100%;
      margin-bottom: 2rem;
      font-size: 2.1rem;
      font-weight: bold;
    }

    .datum {
      margin-top: -2rem;
      margin-bottom: 2rem;
      font-size: 1.4rem;
      color: #444;
    }

    .besedilo {
      width: 100%;
    }
  }
}

.vec {
  margin-left: auto;
  margin-top: auto;
  padding-top: 2rem;
}

.novice.clanek {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  .novica {
    display: block;
    width: percentageWidth(1, 3);
    padding: 10px;
    align-items: center;
    border-bottom: 0;

    @include sm {
      width: 50%;
    }

    @include xs {
      width: 100%;
    }

    p {
      margin: 0;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      height: 0;
    }

    .fotograf {
      margin: 5px 0 0;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    ul {
      margin: 0;
      padding: 2rem;

      li {
        margin-bottom: 10px;
      }
    }
  }
}