%container {
  position: relative;
  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;
  padding: 0 2rem;
}

%clearfix {
  &:after {
	  content: " ";
	  display: block;
	  clear: both;
  }
}