footer {
  padding: 5rem 0;
  background-color: $colorGray;
  color: $colorWhite;

  .container {
    display: flex;

    @include sm {
      flex-wrap: wrap;
      justify-content: center;

      > div {
        margin-bottom: 5rem;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.enovice {
  margin: 0 auto;
  padding: 0 2rem;

  > span {
    font-size: 2.1rem;
    font-weight: bold;
  }

  fieldset {
    margin: 0;
    padding: 0;
    background: none;
  }

  form {
    display: flex;
    align-items: flex-end;

    @include sm {
      margin-top: 1rem;
    }
  }

  input {
    width: 300px;
    padding: 0.5rem;
    border-bottom: 1px solid $colorWhite;
    color: $colorWhite;
    font-size: 1.6rem;

    @include sm {
      width: 230px;
    }
  }

  button {
    width: 50px;
    height: 50px;
    padding: 0.5rem;
    border-bottom: 1px solid $colorWhite;
    color: transparent;
    font-size: 0;
    cursor: pointer;
    background-image: url(../images/icon-mail.png);
    background-size: 35px;
    background-position: center;
    background-repeat: no-repeat;
  }
}