// Mixins for responsive design
@mixin max($maxwidth) {
  @media only screen and (max-width: $maxwidth) {
    @content;
  }
}

@mixin min($minwidth) {
  @media only screen and (min-width: $minwidth) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin transition($element: all, $effect: ease, $duration: 0.2s) {
	transition: $element $effect $duration;
}