// Widths
$md-width:                      1280px;
$sm-width:                      768px;
$xs-width:                      480px;
$containerWidth:               1280px;

// Colors
$colorWhite: #FFFFFF;
$colorBlack: #000000;
$colorGray: #4D4D4D;
$colorDarkGray: #333333;
$colorLightGray: #979797;
$colorGreen: #009880;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,400i,600,700,700i&subset=latin-ext');
$defualtFont: 'Raleway', sans-serif;