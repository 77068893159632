#offset-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  padding: 2rem 4rem;
  background-color: $colorWhite;
  transform: translateX(-100%);
  z-index: 20;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include transition(transform);

  @include md {
    justify-content: flex-start;
  }

  @include sm {
    max-width: 100%;
    padding: 2rem 2rem 5rem;
  }

  &.open {
    transform: translateX(0);
  }

  .menu {
    > li {
      margin-bottom: 2rem;
    }

    a {
      font-size: 1.8rem;
      font-weight: 300;
      color: $colorGray;
      text-transform: uppercase;
      @include transition(color);
    }

    a:hover,
    a.current,
    li.current a {
      color: $colorGreen;
    }

    ul li {
      padding: 0.5rem 0 0 3rem;

      a {
        text-transform: none;
      }
    }
  }
}

.hamburger {
  position: relative;
  width: 50px;
  cursor: pointer;

  .lines {
    position: relative;
    height: 25px;
  }

  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    background-color: $colorWhite;
    @include transition;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 11px;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }

  .text {
    position: absolute;
    top: 45px;
    left: -14px;
    transform: rotate(-90deg);
    color: $colorWhite;
    @include transition(opacity);

    @include sm {
      display: none;
    }
  }

  &.open {
    .text {
      display: none;
    }

    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 18px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 4px;
      }
    }
  }
}

#offset-menu .hamburger {
  position: absolute;
  top: 2rem;
  left: 4rem;

  @include md {
    left: auto;
    right: 2rem;
  }

  span {
    background-color: $colorGray;
  }
}

.logo-menu {
  margin-bottom: 7rem;

  @include md {
    margin: 5rem 0;
  }
}

/*
#content .container,
#title h1,
.home-meni .container {
  @include transition(transform);
}

body.offsetmenu-open {
  #content .container,
  #title h1,
  .home-meni .container {
    transform: translateX(300px);
  }
}
*/